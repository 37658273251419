.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    padding: 20px;
}

.content-container {
    width: 100%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chart-container {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.chart {
    height: 100%;
    width: 100%;
}

@media (min-width: 768px) {
    .App-header {
        justify-content: center;
        align-items: center;
    }

    .content-container {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
    }

    .chart-container {
        flex-grow: 1;
        margin-top: 30px;
        height: 70vh;
        width: 60vw;
        margin-right: 20px;
    }

    .chart {
        min-height: 80%;
        min-width: 70%;
    }

    .leaderboard {
        flex-basis: 40%;
        align-self: flex-start;
        margin-top: 0;
    }
}

.leaderboard {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
}

.leaderboard h2 {
    margin-bottom: 10px;
}

.leaderboard ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.leaderboard li {
    display: flex;
    align-items: center;
    padding: 7px;
    margin-bottom: 3px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 25px;
}

.leaderboard .position {
    margin-right: 10px;
    font-weight: bold;
}

.leaderboard .username {
    margin-right: 10px;
}
.leaderboard .points {
    color: slategray;
}

.leaderboard .difference {
    margin-left: auto;
}

.leaderboard .highlighted {
    background-color: rgba(0, 0, 0, 0.1);
}

.difference-value {
    font-weight: bold;
}

